import React from 'react';

export default function Logo({ svgClass }) {
  return (
    <svg width="104" height="30" viewBox="0 0 104 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={svgClass}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3313 15.0386C17.3313 12.5176 15.5611 10.7923 13.2068 10.7923C10.8528 10.7923 9.08262 12.5176 9.08262 15.0386C9.08262 17.5595 10.8528 19.2845 13.2068 19.2845C15.5611 19.2845 17.3313 17.5595 17.3313 15.0386Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.80488 30C1.71219 30 0 28.2766 0 26.1702V3.82979C0 1.7234 1.71219 0 3.80488 0H35.1951C37.2878 0 39 1.7234 39 3.82979V26.1702C39 28.2766 37.2878 30 35.1951 30H3.80488ZM5.99433 15.0202C5.99433 11.0774 9.04521 8.15846 13.2068 8.15846C17.35 8.15846 20.4196 11.0586 20.4196 15.0202C20.4196 18.9818 17.35 21.8819 13.2068 21.8819C9.04521 21.8819 5.99433 18.9629 5.99433 15.0202ZM13.2068 10.7923C15.5611 10.7923 17.3313 12.5176 17.3313 15.0386C17.3313 17.5595 15.5611 19.2845 13.2068 19.2845C10.8528 19.2845 9.08262 17.5595 9.08262 15.0386C9.08262 12.5176 10.8528 10.7923 13.2068 10.7923ZM22.2691 19.0095L22.0056 19.598C21.8005 20.0566 21.9853 20.5954 22.427 20.8296C23.6351 21.4705 25.3422 21.8819 27.0373 21.8819C30.8038 21.8819 32.6304 19.9864 32.6304 17.769C32.6304 14.9174 30.0044 14.3129 27.8266 13.8116C26.2837 13.4564 24.9658 13.1531 24.9658 12.1392C24.9658 11.3049 25.6628 10.6226 27.4704 10.6226C28.3671 10.6226 29.3192 10.824 30.2673 11.2347C30.7492 11.4438 31.3079 11.2095 31.5058 10.7199L31.7449 10.1273C31.9341 9.65782 31.7284 9.11367 31.2686 8.90846C30.157 8.41154 28.8113 8.15846 27.4894 8.15846C23.7419 8.15846 21.934 10.0347 21.934 12.2908C21.934 15.1729 24.5557 15.786 26.7325 16.2949C28.2776 16.6562 29.5986 16.9272 29.5986 17.9959C29.5986 18.8113 28.8642 19.418 27.0563 19.418C25.8359 19.418 24.5711 19.0794 23.5463 18.5506C23.0707 18.3052 22.4885 18.5196 22.2691 19.0095Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.444 21.8762C46.4037 21.8762 45.4479 21.6772 44.5759 21.2793V19.1688H45.8841V20.4371C46.3873 20.5835 46.9407 20.6565 47.5445 20.6565C48.2989 20.6565 48.8311 20.5317 49.1418 20.2819C49.4522 20.0316 49.6074 19.6806 49.6074 19.2286C49.6074 18.8093 49.4224 18.4742 49.054 18.2242C48.6847 17.9742 48.1064 17.7115 47.3179 17.437C46.2112 17.0469 45.4564 16.6284 45.054 16.1808C44.6516 15.7341 44.4502 15.2094 44.4502 14.6077C44.4502 13.8112 44.7773 13.1566 45.4312 12.6442C46.0851 12.1324 46.9237 11.8762 47.9469 11.8762C48.886 11.8762 49.7747 12.0712 50.6139 12.4613V14.5589H49.3055V13.2664C48.9194 13.1201 48.4414 13.047 47.8713 13.047C47.2341 13.047 46.7557 13.1725 46.4377 13.4234C46.1188 13.6743 45.9597 14.0026 45.9597 14.4072C45.9597 14.8124 46.1313 15.1328 46.4754 15.3672C46.8186 15.6022 47.3598 15.849 48.0979 16.1084C49.1545 16.4668 49.9217 16.8664 50.3995 17.306C50.8775 17.7462 51.1169 18.3325 51.1169 19.0658C51.1169 19.9133 50.7809 20.5935 50.1103 21.1065C49.4394 21.6197 48.5507 21.8762 47.444 21.8762Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60.9458 13.2335L57.6379 23.1424C57.4333 23.7759 57.215 24.3551 56.9841 24.8802C56.7538 25.4052 56.4885 25.8615 56.1895 26.2512C55.8901 26.6399 55.5357 26.9339 55.1255 27.1335C54.715 27.3328 54.2281 27.4318 53.6641 27.4318C53.3561 27.4318 53.0484 27.4049 52.7407 27.3505V25.8575C53.0657 25.9119 53.3821 25.9388 53.6898 25.9388C53.9628 25.9388 54.2154 25.907 54.446 25.8441C54.6766 25.7802 54.8949 25.6543 55.0998 25.4636C55.305 25.2738 55.5186 24.9572 55.7409 24.5137C55.9625 24.0703 56.2108 23.4321 56.4845 22.5999H56.3563L53.3821 13.2335H52.228V11.8762H56.0743V13.2335H54.946L57.1766 20.5095H57.228L59.5609 13.2335H58.3821V11.8762H62.228V13.2335H60.9458Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.694 21.8762C65.6537 21.8762 64.6979 21.6772 63.8259 21.2793V19.1688H65.1341V20.4371C65.6373 20.5835 66.1907 20.6565 66.7945 20.6565C67.5489 20.6565 68.0811 20.5317 68.3918 20.2819C68.7022 20.0316 68.8574 19.6806 68.8574 19.2286C68.8574 18.8093 68.6724 18.4742 68.304 18.2242C67.9347 17.9742 67.3564 17.7115 66.5679 17.437C65.4612 17.0469 64.7064 16.6284 64.304 16.1808C63.9016 15.7341 63.7002 15.2094 63.7002 14.6077C63.7002 13.8112 64.0273 13.1566 64.6812 12.6442C65.3351 12.1324 66.1737 11.8762 67.1969 11.8762C68.136 11.8762 69.0247 12.0712 69.8639 12.4613V14.5589H68.5555V13.2664C68.1694 13.1201 67.6914 13.047 67.1213 13.047C66.4841 13.047 66.0057 13.1725 65.6877 13.4234C65.3688 13.6743 65.2097 14.0026 65.2097 14.4072C65.2097 14.8124 65.3813 15.1328 65.7254 15.3672C66.0686 15.6022 66.6098 15.849 67.3476 16.1084C68.4045 16.4668 69.1717 16.8664 69.6495 17.306C70.1275 17.7462 70.3669 18.3325 70.3669 19.0658C70.3669 19.9133 70.0309 20.5935 69.3603 21.1065C68.6894 21.6197 67.8007 21.8762 66.694 21.8762Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75.4504 21.8768C74.654 21.8768 74.0239 21.6773 73.5615 21.2779C73.0984 20.8791 72.8673 20.2895 72.8673 19.5081V12.5621H71.478V11.2315H72.8673V8.83617L74.534 8.54346V11.2315H77.0336V12.5621H74.534V19.2421C74.534 19.7568 74.6399 20.1028 74.8532 20.2799C75.0658 20.4576 75.3482 20.5459 75.7003 20.5459C76.1446 20.5459 76.5893 20.4396 77.0336 20.2266V21.5311C76.5333 21.7614 76.0058 21.8768 75.4504 21.8768Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M85.6592 21.1203V19.8031C84.7451 20.3397 83.7434 20.6076 82.6535 20.6076C81.669 20.5755 80.9528 20.2422 80.5049 19.6079C80.0564 18.9739 79.8145 18.0875 79.7798 16.9494H85.9228V16.1932C85.9228 14.8112 85.6067 13.7464 84.9736 12.9985C84.3408 12.2502 83.5142 11.8762 82.4953 11.8762C81.2472 11.8762 80.2102 12.3271 79.3845 13.2299C78.5582 14.1322 78.145 15.3804 78.145 16.9738C78.145 18.5189 78.5362 19.7222 79.3183 20.5835C80.1004 21.4454 81.1858 21.8762 82.5745 21.8762C83.7516 21.8762 84.7801 21.6244 85.6592 21.1203ZM83.7875 13.7403C84.1561 14.1714 84.3407 14.8502 84.3407 15.7769H79.8062C79.8937 14.8829 80.1576 14.2124 80.5968 13.7648C81.0361 13.3177 81.581 13.0942 82.2313 13.0942C82.8994 13.0942 83.4183 13.3095 83.7875 13.7403Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M87.0337 20.626H88.3521V13.3757H87.0337V12.1261H89.8281L89.9074 13.4261H89.9335C90.8652 12.393 91.9287 11.8762 93.1239 11.8762C94.3895 11.8762 95.1541 12.4008 95.418 13.4512H95.4705C96.4197 12.4008 97.5093 11.8762 98.7396 11.8762C99.6184 11.8762 100.26 12.1135 100.664 12.5882C101.069 13.0636 101.271 13.7349 101.271 14.6012V20.626H102.589V21.8762H98.6342V20.626H99.6891V14.801C99.6891 14.1345 99.5656 13.6887 99.3199 13.4639C99.0735 13.2384 98.7307 13.1261 98.2917 13.1261C97.3425 13.1261 96.4458 13.5927 95.6023 14.526V20.626H96.6569V21.8762H92.9657V20.626H94.0203V14.801C94.0203 14.1345 93.9016 13.6887 93.6648 13.4639C93.4273 13.2384 93.0975 13.1261 92.6761 13.1261C91.674 13.1261 90.7598 13.6428 89.9335 14.676V20.626H90.9884V21.8762H87.0337V20.626Z" fill="white" />
    </svg>
  )
}
